import './index.css'
import 'bootstrap/dist/css/bootstrap-grid.css'

import { observer } from 'mobx-react-lite'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { AuthenticatedRoute } from './components/authenticatedRoute'
import { Loading } from './components/loading'
import { NonAuthenticatedRoute } from './components/nonAuthenticatedRoute'
import * as serviceWorker from './serviceWorker'

const Financial = React.lazy(() => import('./pages/financial'))
const Employees = React.lazy(() => import('./pages/employees'))
const AceitarConvite = React.lazy(() => import('./pages/aceitarConvite'))
const RecuperarSenha = React.lazy(() => import('./pages/recuperarSenha'))
const Login = React.lazy(() => import('./pages/login'))
const Home = React.lazy(() => import('./pages/home'))
const Invoices = React.lazy(() => import('./pages/invoices'))
const Orders = React.lazy(() => import('./pages/orders'))
const Laboratories = React.lazy(() => import('./pages/laboratories'))
const Customers = React.lazy(() => import('./pages/customers'))
const Statistics = React.lazy(() => import('./pages/statistics'))

const IndexPage = observer(() => (
  // @ts-ignore
  <BrowserRouter>
    <AuthenticatedRoute exact path={`/`}>
      <React.Suspense fallback={<Loading />}>
        <Home />
      </React.Suspense>
    </AuthenticatedRoute>
    <NonAuthenticatedRoute exact path={`/login`}>
      <React.Suspense fallback={<Loading />}>
        <Login />
      </React.Suspense>
    </NonAuthenticatedRoute>
    <NonAuthenticatedRoute exact path={`/aceitarConvite/:token`}>
      <React.Suspense fallback={<Loading />}>
        <AceitarConvite />
      </React.Suspense>
    </NonAuthenticatedRoute>
    <NonAuthenticatedRoute exact path={`/recuperarSenha/:token`}>
      <React.Suspense fallback={<Loading />}>
        <RecuperarSenha />
      </React.Suspense>
    </NonAuthenticatedRoute>
    <AuthenticatedRoute path={`/faturas`}>
      <React.Suspense fallback={<Loading />}>
        <Invoices />
      </React.Suspense>
    </AuthenticatedRoute>
    <AuthenticatedRoute path={`/profissionais`}>
      <React.Suspense fallback={<Loading />}>
        <Customers />
      </React.Suspense>
    </AuthenticatedRoute>
    <AuthenticatedRoute path={`/colaboradores`}>
      <React.Suspense fallback={<Loading />}>
        <Employees />
      </React.Suspense>
    </AuthenticatedRoute>
    <AuthenticatedRoute path={`/financeiro`}>
      <React.Suspense fallback={<Loading />}>
        <Financial />
      </React.Suspense>
    </AuthenticatedRoute>
    <AuthenticatedRoute path={`/ordens`}>
      <React.Suspense fallback={<Loading />}>
        <Orders />
      </React.Suspense>
    </AuthenticatedRoute>
    <AuthenticatedRoute path={`/laboratorios`}>
      <React.Suspense fallback={<Loading />}>
        <Laboratories />
      </React.Suspense>
    </AuthenticatedRoute>
    <AuthenticatedRoute path={`/estatisticas`}>
      <React.Suspense fallback={<Loading />}>
        <Statistics />
      </React.Suspense>
    </AuthenticatedRoute>
  </BrowserRouter>
))

ReactDOM.render(
  <IndexPage />,
  document.getElementById('root')
)

serviceWorker.unregister()
