import axios, { AxiosResponse, AxiosError } from 'axios'

interface ResponseContent {
	success: boolean
	payload?: any
}

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	withCredentials: true,
	timeout: 10000
})

const onResponse = ({ data }: AxiosResponse<ResponseContent>) => {
	if (data.success)
		return data.payload
	else throw new Error(data.payload)
}

const onError = (error: AxiosError<ResponseContent>) => {
	if (error.code === `ECONNABORTED`) {
		throw new Error(`NETWORK_TIMEOUT`)
	} else if (!error.response) {
		throw new Error(`NETWORK_ERROR`)
	} else {
		throw new Error(error.response.data.payload)
	}
}

export const CancelToken = axios.CancelToken.source

api.interceptors.response.use(onResponse, onError)